<template>
  <div class="product-page--create">
    <v-row v-if="!isLoading" no-gutters>
      <v-col cols="12">
        <redirect
          class="mb-5"
          title="Products"
          :routeWithId="{ name: 'website.products.update', params: { id: $route.params.id } }"
        />
        <div class="page-header">
          <h2 class="page-title">{{ $route.params.vId && variantDefault ? variantDefault.title : 'Add variant' }}</h2>
        </div>
      </v-col>
    </v-row>
    <Notification type="error" :messages="errors" v-if="errors.length > 0" />
    <GroupSubmit :mode="'edit'" :isLoading="isLoading" :callbackCancel="onCancel" :callbackSubmit="onSubmit" />
    <v-row v-if="!isLoading">
      <v-col v-if="productData" cols="12" sm="4" class="d-flex flex-column">
        <div class="px-1">
          <div :class="'elevation-form pa-3 d-flex'">
            <img width="50" class="pointer" :src="'@/assets/images/no-image.png'" alt="" />
            <div class="ml-2">
              <h3>{{ productData.title }}</h3>
              <p>{{ productData.variants.length }} variants</p>
            </div>
          </div>
        </div>
        <div class="px-1">
          <div class="elevation-form mt-4 pa-3 px-1">
            <div class="fz-18">Variants ({{ productData.variants.length }} / 250)</div>
            <!-- <v-divider></v-divider> -->
          </div>
        </div>

        <div style="max-height: 900px;overflow: auto;" class="px-1">
          <div
            @click="gotoUpdateVariant(i)"
            :class="
              'elevation-form pa-3 form-component pointer ' +
                (i._id === $route.params.vId ? 'active-variant' : 'inactive-variant')
            "
            v-for="i in productData.variants"
            :key="i._id"
          >
            <v-avatar tile size="50" height="auto">
              <img v-if="i.image && imageKeyList[i.image]" :src="imageHelpers.url(imageKeyList[i.image].src)" alt="" />
              <img class="pointer" v-else :src="'@/assets/images/no-image.png'" alt="" />
            </v-avatar>
            <span class="ml-2 "
              >{{ i.option1 }} {{ i.option2 ? `/${i.option2}` : '' }} {{ i.option3 ? `/${i.option3}` : '' }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="8" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <v-row>
            <!-- <pre>{{ variantDefault }}</pre> -->
            <v-col cols="12" sm="8">
              <div v-for="(i, k) in options" :key="i._id">
                <span>Option: </span> <label class="fz-16" style="font-weight:800">{{ i.name }}</label>
                <v-text-field v-model="variantDefault[`option${k + 1}`]"></v-text-field>
              </div>
            </v-col>
            <!-- {{ variantDefault }} -->
            <v-col cols="12" sm="4">
              <UploadOneImage :image="variantDefault.image" @callback="getImageUpLoad" />
            </v-col>
          </v-row>
        </div>
        <div class="elevation-form mt-4 pa-3">
          <ContentInput
            v-if="!loadingEditor"
            :minHeight="200"
            :model="`descriptionOfVariant`"
            title="descriptionOfVariant"
            subtitle="Maximum 100.000 characters"
          />
        </div>
        <div class="elevation-form mt-4 pa-3">
          <div class="form-component">
            <label for="">
              Pricing
            </label>
            <v-row gutters>
              <v-col cols="6">
                <InputDefault
                  size="12px"
                  label="Price"
                  :model="['variantDefault', 'price']"
                  type="number"
                  :suffix="currency.currencyCode()"
                />
              </v-col>
              <v-col cols="6">
                <InputDefault
                  size="12px"
                  label="Compare at price"
                  :model="['variantDefault', 'compareAtPrice']"
                  type="number"
                  :suffix="currency.currencyCode()"
                />
              </v-col>
              <v-col cols="6">
                <InputDefault
                  size="12px"
                  label="Cost per item"
                  :model="['variantDefault', 'costPerItem']"
                  type="number"
                  :suffix="currency.currencyCode()"
                />
              </v-col>
              <v-col cols="6">
                <InputDefault
                  size="12px"
                  label="Base cost"
                  :model="['variantDefault', 'baseCost']"
                  type="number"
                  :suffix="currency.currencyCode()"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="elevation-form mt-4 pa-3">
          <div class="form-component">
            <label for="">
              Inventory
            </label>
            <v-row gutters>
              <v-col cols="12" md="8">
                <InputDefault size="12px" label="SKU (stock keeping unit)" :model="['variantDefault', 'sku']" />
              </v-col>
              <v-col cols="12" md="4">
                <InputDefault
                  size="12px"
                  label="Barcode (ISBN, UPC, GTIN, etc.)"
                  :model="['variantDefault', 'barCode']"
                />
              </v-col>
              <v-col cols="12" md="8">
                <label class="fz-12">Inventory policy</label>
                <v-select
                  :items="[
                    { name: 'Latte track this product inventory', value: 'latte' },
                    { name: 'Dont track inventory', value: '' },
                  ]"
                  v-model="variantDefault.inventoryManagement"
                  item-value="value"
                  item-text="name"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" v-if="variantDefault.inventoryManagement == 'latte'">
                <InputDefault
                  size="12px"
                  label="Quantity"
                  type="number"
                  :model="['variantDefault', 'inventoryQuantity']"
                />
              </v-col>

              <v-col cols="12" v-if="variantDefault.inventoryManagement == 'latte'">
                <v-checkbox
                  @change="inventoryPolicyChanged"
                  v-model="variantDefault.inventoryPolicy"
                  value="continue"
                  label="Allow customers to purchase this product when it's out of stock"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="elevation-form pa-3 mt-4">
          <div class="form-component">
            <label for="">
              Shipping
            </label>
            <v-row gutters>
              <v-col cols="12">
                <label class="d-flex">WEIGHT </label>
                <span class="d-flex"
                  >Used to calculate shipping rates at checkout and label prices during fulfillment.
                </span>
                <span class="Weight">Weight </span>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-text-field
                      v-model="variantDefault.weight"
                      type="number"
                      hide-details=""
                      aria-autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-select v-model="variantDefault.weightUnit" :items="['lb', 'oz', 'kg', 'g']"></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-btn v-if="$route.params.vId" @click="submitDeleteVariantDeleteVariant" color="error" elevation=""
          >Delete</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
        <v-btn :loading="isLoading" color="primary" @click="onSubmit">Save Variant</v-btn>
      </v-col>
    </v-row>
    <BeforeLeavePage />
    <div style="width: 100%; height: 100%" v-if="isLoading" class="d-flex justify-center align-center">
      <page-loader />
    </div>
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
// import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { productApi } from '@/apis/product';
import { variantApi } from '@/apis/variants';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import ContentInput from '@/views/website/components/Editor';
import currency from '@/helpers/currency';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    ContentInput,
    // ProductAvailability,
  },
  data() {
    let variantDefaultForm = {
      // se an theo 1 trong 2 thang, uu tien variants
      title: 'Default Title',
      active: true,
      barCode: '',
      compareAtPrice: '',
      inventoryManagement: '', // [no, hiweb]Nhập, mặc định là no, nếu no không cập nhật quantity, nếu  hiweb thì cập nhật sau khi có order
      costPerItem: null, // cost item
      baseCost: null, // estimated price
      inventoryPolicy: 'continue', //continue, deny
      inventoryQuantity: null, // Số sản phẩm trong store, 0 = Soldout
      requiresShipping: true,
      weight: 0,
      weightUnit: 'lb',
      taxable: true,
      isDefault: false,
      image: {},
      price: null,
      sku: '',
      descriptionOfVariant: '',
      fulfillmentService: '',
      option1: null,
      option2: null,
      option3: null,
      imageKeyList: {},
    };
    return {
      currency,
      loadingEditor: false,
      attributes: {
        descriptionOfVariant: '',
      },
      errors: [],
      imageHelpers,
      imageFake,
      isLoading: false,
      page: {},
      showHeader: false,
      searchString: 'null hihi',
      variantDefault: Object.assign({}, variantDefaultForm),
      variantDefaultForm,
      listVariants: [],
      // Product options
      options: [], // need
      productData: null,
      imageKeyList: {},
    };
  },
  async created() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  watch: {
    $route: function() {
      this.getData();
    },
  },
  methods: {
    inventoryPolicyChanged(e) {
      this.variantDefault = {
        ...this.variantDefault,
        inventoryPolicy: e == 'continue' ? 'continue' : 'deny',
      };
    },
    async submitDeleteVariantDeleteVariant() {
      try {
        let res = await variantApi.remove(this.$route.params.vId);
        // this.$parent.getData();
        this.$router.push({ name: 'website.products.update', params: { id: this.$route.params.id } });
        this.showDialogDelete = false;
      } catch (error) {
        console.log(error);
      }
    },
    changeOption(e) {
      console.log(e);
    },
    gotoUpdateVariant(item) {
      if (item._id !== this.$route.params.vId) {
        this.$router.push({
          name: 'website.products.variants.update',
          params: {
            id: this.$route.params.id,
            vId: item._id,
          },
        });
      }
    },
    getImageUpLoad(images) {
      console.log('images', images);
      this.variantDefault.image = images;
      this.$forceUpdate();
    },
    onCancel() {
      this.$v.$reset();
      this.variantDefault = Object.assign({}, this.defaultForm);
      this.$forceUpdate();
      this.$router.push({ name: 'website.products' });
    },
    convertTitle() {
      let t = '';
      for (let i = 0; i < this.options.length; i++) {
        t = t + (t !== '' ? `/${this.variantDefault[`option${i + 1}`]}` : `${this.variantDefault[`option${i + 1}`]}`);
        console.log('t=>>', t);
      }
      return t;
    },
    async onSubmit() {
      this.$v.$touch();
      utilities.scrollToTop();
      try {
        if (!this.$v.$invalid) {
          // this.isLoading = true;
          if (this.$route.params.vId) {
            const dataPost = {
              ...this.variantDefault,
              descriptionOfVariant: this.attributes.descriptionOfVariant,
              product: this.$route.params.id,
              price: parseFloat(this.variantDefault.price),
              compareAtPrice: parseFloat(this.variantDefault.compareAtPrice),
              costPerItem: parseFloat(this.variantDefault.costPerItem),
              baseCost: parseFloat(this.variantDefault.baseCost),
              title: this.convertTitle(),
              inventoryPolicy:
                this.variantDefault.inventoryManagement == 'latte' ? this.variantDefault.inventoryPolicy : 'continue',
            };
            if (dataPost.price) {
              await variantApi.update(this.$route.params.vId, dataPost);
              this.variantDefault = Object.assign({}, this.variantDefaultForm);
              this.getData();
            } else {
              this.$store.commit('setMessages', {
                messages: 'You have to set price for this variant!',
                type: 'error',
              });
              this.isLoading = false;
            }
          } else {
            let checkOption = 0;
            let isShowError = false;

            for (let i = 0; i < this.options.length; i++) {
              if (this.variantDefault[`option${i + 1}`]) {
                checkOption = checkOption + 1;
                isShowError = true;
              }
              if (checkOption === this.options.length && isShowError) {
                this.errors = [];
                const dataPost = {
                  ...this.variantDefault,
                  descriptionOfVariant: this.attributes.descriptionOfVariant,
                  price: parseFloat(this.variantDefault.price),
                  compareAtPrice: parseFloat(this.variantDefault.compareAtPrice),
                  costPerItem: parseFloat(this.variantDefault.costPerItem),
                  baseCost: parseFloat(this.variantDefault.baseCost),
                  title: this.convertTitle(),
                };
                if (dataPost.price) {
                  await variantApi.create(this.$route.params.id, dataPost);
                  this.variantDefault = Object.assign({}, this.variantDefaultForm);
                  this.getData();
                } else {
                  this.$store.commit('setMessages', {
                    messages: 'You have to set price for this variant!',
                    type: 'error',
                  });
                  this.isLoading = false;
                }
              } else {
                this.errors = ['Options required'];
              }
            }
          }
        }
      } catch (error) {
        console.log('error', error.response);
      } finally {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async getData() {
      this.attributes.descriptionOfVariant = '';
      this.loadingEditor = true;
      try {
        // this.loading = true;
        this.productData = (await productApi.getById(this.$route.params.id)).data.product;
        this.options = this.productData.options;
        if (this.$route.params.vId) {
          let variantRes = (await variantApi.getById(this.$route.params.vId)).data.variant;
          this.variantDefault = {
            compareAtPrice: variantRes.compareAtPrice ? variantRes.compareAtPrice : 0,
            costPerItem: variantRes.costPerItem,
            baseCost: variantRes.baseCost,
            inventoryPolicy: variantRes.inventoryPolicy,
            sku: variantRes.sku,
            barCode: variantRes.barCode,
            weight: variantRes.weight,
            weightUnit: variantRes.weightUnit,
            price: variantRes.price ? variantRes.price : 0,
            image: variantRes.image,
            title: variantRes.title,
            descriptionOfVariant: variantRes.descriptionOfVariant,
            inventoryManagement: variantRes.inventoryManagement,
            inventoryQuantity: variantRes.inventoryQuantity,
            // image:
          };
          this.attributes.descriptionOfVariant = variantRes.descriptionOfVariant;
          for (let index = 0; index < this.options.length; index++) {
            this.variantDefault[`option${index + 1}`] = variantRes[`option${index + 1}`];
            this.$forceUpdate();
          }
        }
        for (let index = 0; index < this.productData.images.length; index++) {
          this.imageKeyList[this.productData.images[index]._id] = this.productData.images[index];
        }
      } catch (error) {
        console.log(error);
      }
      this.loadingEditor = false;

      // this.loading = false;
    },
  },
  validations: {},
};
</script>
<style lang="scss">
.product-page--create {
  .btn-link {
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  .active-variant {
    background-color: #4181cb;
    color: white !important;
    font-weight: 700;
  }
  .inactive-variant {
    color: $main-color;
    &:hover {
      text-decoration: underline;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
